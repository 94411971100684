import React, { Component } from 'react';

import '../../assets/styles/PageLoader.scss';

class PageLoader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            finishedloading : false
        }
    }

    componentDidMount() {
        this.setState(prevProps => ({
            finishedloading: false
        }));
    }

    componentDidUpdate(prevProps) {
        if (this.props.finishedloading !== prevProps.finishedloading) {

            if (this.props.finishedloading) {
                setTimeout(
                    function() {
                        this.setState(prevProps => ({
                            finishedloading: !prevProps.finishedloading
                        }));
                    }
                    .bind(this),
                    200
                );
            } else {
                this.setState(prevProps => ({
                    finishedloading: !prevProps.finishedloading
                }));
            }
        }
    }
    
    render() {
        return (
            <div id="pageLoader" className={this.state.finishedloading ? 'finished' : 'loading'}>
                <svg version='1.2' baseProfile='tiny' xmlns='http://www.w3.org/2000/svg'
                viewBox='0 0 140 160' height='350' width='350' className='logoSvg'>
                    <g>
                        <polygon 
                            id='loader-logo-y' 
                            points='0,25.5 32,90.4 0,155.4 9,155.4 41,90.4 9,25.5'
                            fill='transparent'
                        />
                        <polygon 
                            id='loader-logo-7' 
                            points='24.2,1 54.8,62.4 73,25.5 100.3,25.5 36.3,155.4 63.7,155.4 139.9,1'
                            fill='transparent'
                        />
                    </g>
                </svg>
            </div>
        )
    }
}


export default PageLoader;