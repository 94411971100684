import React, { Component } from 'react';

class SvgGradients extends Component {
    render() {
        return(
        <svg style={{width:0, height:0, position:'absolute'}} aria-hidden="true" focusable="false">
            <linearGradient id="bluegradient" x2="1" y2="1">
                <stop offset="0%" stopColor="#22E1FF" />
                <stop offset="50%" stopColor="#1D8FE1" />
                <stop offset="100%" stopColor="#625EB1" />
            </linearGradient>
            <linearGradient id="whitegradient" x2="1" y2="1">
                <stop offset="0%" stopColor="#FFFFFF" />
                <stop offset="50%" stopColor="#F6F6F6" />
                <stop offset="100%" stopColor="#EDEDED" />
            </linearGradient>
            <linearGradient id="blackgradient" x2="1" y2="1">
                <stop offset="0%" stopColor="#333333" />
                <stop offset="50%" stopColor="#171717" />
                <stop offset="100%" stopColor="#000000" />
            </linearGradient>
            <linearGradient id="orangegradient" x2="1" y2="1">
                <stop offset="0%" stopColor="#f39200" />
                <stop offset="100%" stopColor="#f57800" />
            </linearGradient>
            <linearGradient id="shinegradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#FFFFFF" stopOpacity="0" />
                <stop offset="50%" stopColor="#FFFFFF" stopOpacity="0.5" />
                <stop offset="100%" stopColor="#FFFFFF" stopOpacity="0" />
            </linearGradient>
            <linearGradient id="brighter-shinegradient" x1="0%" y1="0%" x2="0%" y2="100%">
                <stop offset="0%" stopColor="#FFFFFF" stopOpacity="0" />
                <stop offset="50%" stopColor="#FFFFFF" stopOpacity="0.8" />
                <stop offset="100%" stopColor="#FFFFFF" stopOpacity="0" />
            </linearGradient>
        </svg>
        )
    }
}

export default SvgGradients


