import React, { Component } from 'react';

// styles
import '../../assets/styles/Logo.scss';

const browserCanAnimate = () => {
        // Safari 3.0+ "[object HTMLElementConstructor]" 
    const isSafari = /constructor/i.test(window.HTMLElement) || (function (p) { return p.toString() === "[object SafariRemoteNotification]"; })(!window['safari'] || (typeof safari !== 'undefined' && safari.pushNotification));

    // Internet Explorer 6-11
    const isIE = /*@cc_on!@*/false || !!document.documentMode;

    // Edge 20+
    const isEdge = !isIE && !!window.StyleMedia;

    return (isSafari || isIE || isEdge) ? false : true;
}

class Logo extends Component {

    constructor(props) {
        super(props);
        this.animation = this.setLogoAnimation();
    }

    setLogoAnimation() {
        let result = (browserCanAnimate() && this.props.animation != false) ? true : false;
        return result;
    }

    render() {
        const viewBoxValue = this.props.slogan ? '0 0 322.27 223.98' : '0 0 322.27 154.31'
        const darkMode = this.props.darkmode ? 'url(#whitegradient)' : 'url(#blackgradient)';
        const uniqueId = this.props.uniqueid ? this.props.uniqueid : '';

        return (
            <svg 
                version='1.2' 
                baseProfile='tiny' 
                xmlns='http://www.w3.org/2000/svg'
                viewBox={viewBoxValue}
                height={this.props.height} 
                width={this.props.width} 
                className={this.animation ? 'logoSvg animation' : 'logoSvg no-animation'}
            >   
                <defs>
                    <filter id="dropShadow" x="-200%" y="-200%" width="500%" height="500%" filterUnits="userSpaceOnUse">
                        <feOffset result="offOut" in="SourceAlpha" dx="0" dy="0" />
                        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10" />
                        <feComponentTransfer>
                            <feFuncA type="linear" slope="0.5"/>
                        </feComponentTransfer>
                        <feMerge> 
                            <feMergeNode/>
                            <feMergeNode in="SourceGraphic"/> 
                            <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                        </feMerge>
                    </filter>
                </defs>
                <title>Y7web developement</title>


                {/* <clipPath id={"y7-clip" + uniqueId}>
                    <polygon 
                        id="logo-y"
                        points='25.29 24.49 57.28 89.46 25.29 154.42 34.29 154.42 66.28 89.46 34.29 24.49 25.29 24.49'
                    >
                    </polygon>
                    <polygon 
                        id="logo-7"
                        points='49.49 0 80.1 61.46 98.28 24.49 125.62 24.49 61.63 154.42 88.98 154.42 165.15 0 49.49 0'
                    >
                    </polygon>
                </clipPath> */}
                <g id='logo-web'>
                    <path 
                        id='logo-w' 
                        fill={darkMode}
                        d='M190.81,105.13l-7.13,49h-10.3l-7.08-20.51-1.41-5-1.62,5.27-6.78,20.22h-10l-7.08-49h8.3l4.1,33.3.88,7.42,2.1-6.5,7.13-22H168l7.67,21.73,2.19,6.5.74-6.89,3.81-33.54Z'
                    />
                    <path 
                        id='logo-e' 
                        fill={darkMode}
                        d='M241.79,127.05c0,1.2,0,2.21,0,3s-.08,1.58-.15,2.29H207.17c0,5,1.4,8.86,4.2,11.55s6.84,4,12.11,4c1.43,0,2.86-.06,4.3-.17s2.81-.27,4.15-.47,2.61-.41,3.83-.66,2.35-.51,3.39-.8v7a59.92,59.92,0,0,1-7.83,1.59,66.69,66.69,0,0,1-9.06.61,30.79,30.79,0,0,1-10.84-1.71,18.53,18.53,0,0,1-7.45-5,20.29,20.29,0,0,1-4.29-8,37.51,37.51,0,0,1-1.4-10.66,31.74,31.74,0,0,1,1.49-9.79,24.62,24.62,0,0,1,4.35-8.11,20.61,20.61,0,0,1,16.43-7.59,23.77,23.77,0,0,1,9.08,1.61,18.31,18.31,0,0,1,6.67,4.56,19.65,19.65,0,0,1,4.1,7.18A30.07,30.07,0,0,1,241.79,127.05ZM233,125.83a18.46,18.46,0,0,0-.63-6,12.66,12.66,0,0,0-2.42-4.67,11.35,11.35,0,0,0-4.08-3.05,13.67,13.67,0,0,0-5.66-1.1,11.77,11.77,0,0,0-5.08,1.08,11.46,11.46,0,0,0-4,3,15.47,15.47,0,0,0-2.69,4.68,21.73,21.73,0,0,0-1.27,6Z'
                    />
                    <path 
                        id='logo-b' 
                        fill={darkMode}
                        d='M297.26,128.76a32.67,32.67,0,0,1-1.69,10.91,22.9,22.9,0,0,1-4.78,8.16,20.48,20.48,0,0,1-7.54,5.12,26.69,26.69,0,0,1-10,1.79,49.71,49.71,0,0,1-8.89-.83,46.49,46.49,0,0,1-9-2.64V85.16h8.5v19l-.39,9.08a22,22,0,0,1,7.84-7,20.25,20.25,0,0,1,9-2,15,15,0,0,1,7.37,1.75,15.43,15.43,0,0,1,5.32,5,23.38,23.38,0,0,1,3.22,7.72A43.4,43.4,0,0,1,297.26,128.76Zm-8.69.39a41.83,41.83,0,0,0-.56-7.08,18.46,18.46,0,0,0-1.79-5.52A9.8,9.8,0,0,0,283.1,113a7.89,7.89,0,0,0-4.54-1.29,11,11,0,0,0-3.27.51,13.16,13.16,0,0,0-3.45,1.71,26.84,26.84,0,0,0-3.76,3.17,52.22,52.22,0,0,0-4.22,4.91v23.78a32.64,32.64,0,0,0,5.08,1.54,23.52,23.52,0,0,0,4.93.56,16.81,16.81,0,0,0,5.59-.93,11.57,11.57,0,0,0,4.69-3.1,15.58,15.58,0,0,0,3.22-5.76A28.4,28.4,0,0,0,288.57,129.15Z'
                    />
                </g>

                <g id="logo-y7">
                    <g id="logo-arrow-container">
                        <polygon 
                            id="logo-y"
                            points='25.29 24.49 57.28 89.46 25.29 154.42 34.29 154.42 66.28 89.46 34.29 24.49 25.29 24.49'
                            fill='url(#orangegradient)' 
                        >
                        </polygon>
                    </g>
                    
                    <g id="logo-7-container">
                        <polygon 
                            id="logo-7"
                            points='49.49 0 80.1 61.46 98.28 24.49 125.62 24.49 61.63 154.42 88.98 154.42 165.15 0 49.49 0'
                            fill='url(#orangegradient)' 
                        >
                        </polygon>
                    </g>
                </g>    
                {this.props.slogan && 
                    <g id="logo-slogan">
                        <path
                            fill={darkMode}
                            d="M-.12,202.32a10.27,10.27,0,0,1,.53-3.46,7.15,7.15,0,0,1,1.51-2.56,6.5,6.5,0,0,1,2.36-1.58,8.35,8.35,0,0,1,3.07-.54,11.48,11.48,0,0,1,1.44.09,9.54,9.54,0,0,1,1.39.29v-6.42h2.66v21.5H10.47l-.09-2.89A8,8,0,0,1,8,209.14a5.31,5.31,0,0,1-2.8.78,4.74,4.74,0,0,1-2.3-.55,4.64,4.64,0,0,1-1.66-1.55,7.33,7.33,0,0,1-1-2.39A13.61,13.61,0,0,1-.12,202.32Zm2.7-.17a8.3,8.3,0,0,0,.82,4.14,2.56,2.56,0,0,0,2.3,1.36,3.48,3.48,0,0,0,2.13-.9,13.15,13.15,0,0,0,2.35-2.66V197a6.84,6.84,0,0,0-1.45-.46,7.58,7.58,0,0,0-1.56-.16,4.24,4.24,0,0,0-3.38,1.4A6.64,6.64,0,0,0,2.58,202.15Z"/>
                        <path
                            fill={darkMode}
                            d="M30.7,201.19c0,.38,0,.69,0,.95s0,.49-.05.71H19.9a4.74,4.74,0,0,0,1.31,3.6A5.25,5.25,0,0,0,25,207.71c.44,0,.89,0,1.34-.05s.87-.09,1.29-.15l1.2-.2c.38-.08.73-.16,1.05-.26v2.18a19.35,19.35,0,0,1-2.44.5,21.53,21.53,0,0,1-2.82.19,9.47,9.47,0,0,1-3.38-.54,5.7,5.7,0,0,1-2.32-1.54,6.32,6.32,0,0,1-1.34-2.48,11.73,11.73,0,0,1-.44-3.33A9.88,9.88,0,0,1,17.6,199,7.78,7.78,0,0,1,19,196.45a6.47,6.47,0,0,1,5.12-2.36,7.42,7.42,0,0,1,2.84.5A5.63,5.63,0,0,1,29,196a6,6,0,0,1,1.28,2.24A9.36,9.36,0,0,1,30.7,201.19Zm-2.76-.38a5.81,5.81,0,0,0-.2-1.86A3.85,3.85,0,0,0,27,197.5a3.35,3.35,0,0,0-1.27-.95A4.27,4.27,0,0,0,24,196.2a3.59,3.59,0,0,0-1.58.34,3.55,3.55,0,0,0-1.23.94,4.74,4.74,0,0,0-.84,1.46,7.21,7.21,0,0,0-.4,1.87Z"/>
                        <path
                            fill={darkMode}
                            d="M33.5,194.36h3l3.74,10.11.81,2.46.84-2.52,3.71-10h2.91l-6,15.28h-3Z"/>
                        <path
                            fill={darkMode}
                            d="M65,201.19c0,.38,0,.69,0,.95s0,.49,0,.71H54.18a4.74,4.74,0,0,0,1.31,3.6,5.22,5.22,0,0,0,3.77,1.26c.45,0,.9,0,1.34-.05s.88-.09,1.3-.15l1.19-.2c.38-.08.73-.16,1.06-.26v2.18a19.72,19.72,0,0,1-2.44.5,21.57,21.57,0,0,1-2.83.19,9.51,9.51,0,0,1-3.38-.54,5.84,5.84,0,0,1-2.32-1.54,6.45,6.45,0,0,1-1.34-2.48,11.74,11.74,0,0,1-.43-3.33,10.19,10.19,0,0,1,.46-3.05,7.8,7.8,0,0,1,1.36-2.53,6.46,6.46,0,0,1,2.18-1.72,6.54,6.54,0,0,1,2.94-.64,7.33,7.33,0,0,1,2.83.5A5.66,5.66,0,0,1,63.26,196a6.17,6.17,0,0,1,1.28,2.24A9.36,9.36,0,0,1,65,201.19Zm-2.75-.38A5.81,5.81,0,0,0,62,199a3.85,3.85,0,0,0-.75-1.45,3.38,3.38,0,0,0-1.28-.95,4.19,4.19,0,0,0-1.76-.35,3.55,3.55,0,0,0-1.58.34,3.68,3.68,0,0,0-1.24.94,4.93,4.93,0,0,0-.83,1.46,6.47,6.47,0,0,0-.4,1.87Z"/>
                        <path
                            fill={darkMode}
                            d="M74.41,190.31H69.89v-2.17h7.2v19.29h4.55v2.21H69.38v-2.21h5Z"/>
                        <path
                            fill={darkMode}
                            d="M99.64,201.88a10,10,0,0,1-.5,3.26,7.26,7.26,0,0,1-1.45,2.55,6.45,6.45,0,0,1-2.29,1.64,7.72,7.72,0,0,1-3.08.59,8,8,0,0,1-2.94-.51,5.79,5.79,0,0,1-2.21-1.5,6.74,6.74,0,0,1-1.39-2.45,11.15,11.15,0,0,1-.47-3.37,9.89,9.89,0,0,1,.5-3.25,7.1,7.1,0,0,1,1.44-2.52,6.48,6.48,0,0,1,2.3-1.65,7.69,7.69,0,0,1,3.08-.58,8,8,0,0,1,2.94.51,5.84,5.84,0,0,1,2.21,1.49,6.59,6.59,0,0,1,1.38,2.44A10.75,10.75,0,0,1,99.64,201.88Zm-2.71.12a8.68,8.68,0,0,0-.31-2.47,4.91,4.91,0,0,0-.89-1.77,3.61,3.61,0,0,0-1.41-1.06,4.59,4.59,0,0,0-1.85-.36,4.17,4.17,0,0,0-2,.46A4.12,4.12,0,0,0,89.06,198a5.22,5.22,0,0,0-.79,1.81,9.41,9.41,0,0,0,.06,4.63,4.88,4.88,0,0,0,.89,1.78,3.65,3.65,0,0,0,1.4,1.06,4.52,4.52,0,0,0,1.85.36,4.18,4.18,0,0,0,2-.47A4,4,0,0,0,95.89,206a5.36,5.36,0,0,0,.79-1.8A9,9,0,0,0,96.93,202Z"/>
                        <path
                            fill={darkMode}
                            d="M116.54,201.73a10.15,10.15,0,0,1-.57,3.56,7,7,0,0,1-1.58,2.53,6.39,6.39,0,0,1-2.37,1.5,8.5,8.5,0,0,1-3,.5,14.27,14.27,0,0,1-1.46-.07,10.93,10.93,0,0,1-1.46-.26v6.39h-2.65V194.36h2.36l.16,2.56a6.87,6.87,0,0,1,2.44-2.2,6.31,6.31,0,0,1,2.8-.63,4.74,4.74,0,0,1,2.3.54,4.81,4.81,0,0,1,1.66,1.55,7.38,7.38,0,0,1,1,2.4A13.82,13.82,0,0,1,116.54,201.73Zm-2.71.12a12.59,12.59,0,0,0-.18-2.21,5.7,5.7,0,0,0-.55-1.72,3.16,3.16,0,0,0-1-1.12,2.49,2.49,0,0,0-1.41-.4,3.29,3.29,0,0,0-1,.16,4.3,4.3,0,0,0-1.08.53,8.11,8.11,0,0,0-1.17,1,14.63,14.63,0,0,0-1.31,1.53V207a7.79,7.79,0,0,0,1.53.48,7.32,7.32,0,0,0,1.59.18,4.14,4.14,0,0,0,3.36-1.46A6.66,6.66,0,0,0,113.83,201.85Z"/>
                        <path
                            fill={darkMode}
                            d="M131.51,209.64v-11a9.48,9.48,0,0,0,0-1.17,2.71,2.71,0,0,0-.17-.73.74.74,0,0,0-.29-.37.81.81,0,0,0-.43-.11.9.9,0,0,0-.56.19,2.47,2.47,0,0,0-.56.59,8,8,0,0,0-.65,1.09c-.24.45-.52,1-.85,1.66v9.82h-2.42V199c0-.56,0-1,0-1.36a3.13,3.13,0,0,0-.17-.8.68.68,0,0,0-.3-.4.84.84,0,0,0-.44-.11.88.88,0,0,0-.51.16,2.11,2.11,0,0,0-.55.54,12.37,12.37,0,0,0-.66,1.08c-.24.46-.53,1-.86,1.75v9.82h-2.44V194.36h2l.13,2.91a13.28,13.28,0,0,1,.77-1.48,4.76,4.76,0,0,1,.76-1,2.74,2.74,0,0,1,.85-.55,2.86,2.86,0,0,1,1-.17,2.27,2.27,0,0,1,1.88.8,4,4,0,0,1,.64,2.5c.24-.53.48-1,.71-1.41a5.37,5.37,0,0,1,.75-1,2.76,2.76,0,0,1,.9-.64,2.63,2.63,0,0,1,1.12-.22c1.92,0,2.88,1.47,2.88,4.43v11.12Z"/>
                        <path
                            fill={darkMode}
                            d="M150.66,201.19c0,.38,0,.69,0,.95s0,.49,0,.71H139.87a4.74,4.74,0,0,0,1.31,3.6,5.22,5.22,0,0,0,3.77,1.26c.45,0,.9,0,1.34-.05s.88-.09,1.3-.15l1.19-.2c.38-.08.73-.16,1.06-.26v2.18a19.72,19.72,0,0,1-2.44.5,21.57,21.57,0,0,1-2.83.19,9.51,9.51,0,0,1-3.38-.54,5.84,5.84,0,0,1-2.32-1.54,6.45,6.45,0,0,1-1.34-2.48,11.74,11.74,0,0,1-.43-3.33,9.88,9.88,0,0,1,.46-3.05,7.61,7.61,0,0,1,1.36-2.53,6.43,6.43,0,0,1,5.12-2.36,7.37,7.37,0,0,1,2.83.5A5.75,5.75,0,0,1,149,196a6.17,6.17,0,0,1,1.28,2.24A9.36,9.36,0,0,1,150.66,201.19Zm-2.75-.38a5.81,5.81,0,0,0-.2-1.86,3.86,3.86,0,0,0-.76-1.45,3.35,3.35,0,0,0-1.27-.95,4.19,4.19,0,0,0-1.76-.35,3.55,3.55,0,0,0-1.58.34,3.68,3.68,0,0,0-1.24.94,5.17,5.17,0,0,0-.84,1.46,6.82,6.82,0,0,0-.39,1.87Z"/>
                        <path
                            fill={darkMode}
                            d="M154.89,194.36h2.36l.11,2.47a10.17,10.17,0,0,1,1.29-1.32,6.73,6.73,0,0,1,1.23-.84,4.1,4.1,0,0,1,1.22-.45,6.14,6.14,0,0,1,1.29-.13,4.45,4.45,0,0,1,3.57,1.39,6.24,6.24,0,0,1,1.21,4.19v10h-2.64v-9.75a4.35,4.35,0,0,0-.67-2.66,2.39,2.39,0,0,0-2-.86,3.41,3.41,0,0,0-1,.14,3.65,3.65,0,0,0-1,.51,8.69,8.69,0,0,0-1.08,1q-.59.61-1.32,1.53v10.13h-2.65Z"/>
                        <path
                            fill={darkMode}
                            d="M184.34,209.43a14,14,0,0,1-1.85.33q-1,.09-2,.09a6.13,6.13,0,0,1-4.29-1.3,5.14,5.14,0,0,1-1.42-4v-8h-4.28v-2.22h4.28v-4.2l2.65-.69v4.89h6.86v2.22h-6.86v7.76a3.21,3.21,0,0,0,.87,2.46,3.65,3.65,0,0,0,2.58.82,11.74,11.74,0,0,0,1.6-.12,14.37,14.37,0,0,0,1.81-.35Z"/>
                        <path
                            fill={darkMode}
                            d="M218.4,205.47a3.55,3.55,0,0,1-1,2.58,4.62,4.62,0,0,1-1.09.85,7.5,7.5,0,0,1-1.33.57,8,8,0,0,1-1.46.34,10.36,10.36,0,0,1-1.46.11,27.74,27.74,0,0,1-2.85-.14,19.4,19.4,0,0,1-2.55-.44V206.9a19.8,19.8,0,0,0,2.66.58,18.15,18.15,0,0,0,2.64.2,5.84,5.84,0,0,0,2.81-.52,1.62,1.62,0,0,0,.92-1.48,1.7,1.7,0,0,0-.15-.73,1.75,1.75,0,0,0-.52-.63,5,5,0,0,0-1.18-.61c-.54-.22-1.27-.46-2.19-.73a16.27,16.27,0,0,1-1.91-.7,6.2,6.2,0,0,1-1.51-.92,3.92,3.92,0,0,1-1-1.25,3.7,3.7,0,0,1-.36-1.69,4.07,4.07,0,0,1,.29-1.4,3.89,3.89,0,0,1,1-1.41,5.52,5.52,0,0,1,1.93-1.09,9.15,9.15,0,0,1,3-.43c.6,0,1.27,0,2,.1a19.67,19.67,0,0,1,2.28.34v2.36a20.82,20.82,0,0,0-2.37-.45,14.77,14.77,0,0,0-1.94-.15,7.12,7.12,0,0,0-1.66.16,3.57,3.57,0,0,0-1.1.41,1.63,1.63,0,0,0-.6.63,1.68,1.68,0,0,0-.18.77,1.64,1.64,0,0,0,.16.74,1.68,1.68,0,0,0,.59.65,5.37,5.37,0,0,0,1.21.62,21.26,21.26,0,0,0,2,.68,15.49,15.49,0,0,1,2.29.83,5.63,5.63,0,0,1,1.5,1,2.93,2.93,0,0,1,.83,1.2A4.25,4.25,0,0,1,218.4,205.47Z"/>
                        <path
                            fill={darkMode}
                            d="M236.35,201.19c0,.38,0,.69,0,.95s0,.49,0,.71H225.56a4.71,4.71,0,0,0,1.31,3.6,5.22,5.22,0,0,0,3.77,1.26c.45,0,.89,0,1.34-.05s.88-.09,1.29-.15l1.2-.2c.38-.08.73-.16,1.06-.26v2.18a19.52,19.52,0,0,1-2.45.5,21.42,21.42,0,0,1-2.82.19,9.51,9.51,0,0,1-3.38-.54,5.77,5.77,0,0,1-2.32-1.54,6.45,6.45,0,0,1-1.34-2.48,11.74,11.74,0,0,1-.43-3.33,9.88,9.88,0,0,1,.46-3.05,7.61,7.61,0,0,1,1.36-2.53,6.43,6.43,0,0,1,5.12-2.36,7.37,7.37,0,0,1,2.83.5,5.75,5.75,0,0,1,2.08,1.42,6.17,6.17,0,0,1,1.28,2.24A9.66,9.66,0,0,1,236.35,201.19Zm-2.76-.38a5.82,5.82,0,0,0-.19-1.86,4,4,0,0,0-.76-1.45,3.35,3.35,0,0,0-1.27-.95,4.22,4.22,0,0,0-1.76-.35,3.63,3.63,0,0,0-1.59.34,3.74,3.74,0,0,0-1.23.94,5.17,5.17,0,0,0-.84,1.46,6.82,6.82,0,0,0-.39,1.87Z"/>
                        <path
                            fill={darkMode}
                            d="M241.2,194.36h2.42l.08,2.82a9.55,9.55,0,0,1,2.67-2.36,5.39,5.39,0,0,1,2.66-.73,4.35,4.35,0,0,1,3.6,1.53,6.94,6.94,0,0,1,1.13,4.57h-2.68a5,5,0,0,0-.58-2.91,2.11,2.11,0,0,0-1.85-.91,3.15,3.15,0,0,0-1.08.19,4.4,4.4,0,0,0-1.11.61,11,11,0,0,0-1.21,1.07c-.42.44-.88,1-1.37,1.58v9.82H241.2Z"/>
                        <path
                            fill={darkMode}
                            d="M256.29,194.36h3l3.75,10.11.8,2.46.84-2.52,3.71-10h2.91l-6,15.28h-3Z"/>
                        <path
                            fill={darkMode}
                            d="M280.06,196.55h-4.52v-2.19h7.2v13.07h4.55v2.21H275v-2.21h5ZM281,188a2.22,2.22,0,0,1,.82.16,1.93,1.93,0,0,1,.66.45,2.42,2.42,0,0,1,.45.66,2.18,2.18,0,0,1,.16.82,2.15,2.15,0,0,1-.16.8,2.08,2.08,0,0,1-.45.67,1.69,1.69,0,0,1-.66.45,2.18,2.18,0,0,1-1.64,0,1.69,1.69,0,0,1-.66-.45,2.08,2.08,0,0,1-.45-.67,2.15,2.15,0,0,1-.16-.8,2.18,2.18,0,0,1,.16-.82,2.42,2.42,0,0,1,.45-.66,1.93,1.93,0,0,1,.66-.45A2.22,2.22,0,0,1,281,188Z"/>
                        <path
                            fill={darkMode}
                            d="M303.87,209.08a11.18,11.18,0,0,1-2.13.58,12.62,12.62,0,0,1-2.24.19,7.51,7.51,0,0,1-5.58-2,7.81,7.81,0,0,1-2-5.74,9,9,0,0,1,.57-3.28,7.19,7.19,0,0,1,1.58-2.53,7,7,0,0,1,2.44-1.62,8.25,8.25,0,0,1,3.12-.57,13,13,0,0,1,2.22.17,9.33,9.33,0,0,1,2,.54v2.53a8.39,8.39,0,0,0-2-.75,9.31,9.31,0,0,0-2.12-.24,4.82,4.82,0,0,0-1.93.39,4.74,4.74,0,0,0-1.59,1.12,5.12,5.12,0,0,0-1.08,1.78,6.77,6.77,0,0,0-.4,2.37,5.71,5.71,0,0,0,1.35,4.15,5,5,0,0,0,3.74,1.38,8.54,8.54,0,0,0,2.09-.25,8.45,8.45,0,0,0,2-.73Z"/>
                        <path
                            fill={darkMode}
                            d="M322,201.19c0,.38,0,.69,0,.95s0,.49,0,.71H311.25a4.71,4.71,0,0,0,1.31,3.6,5.22,5.22,0,0,0,3.77,1.26c.45,0,.89,0,1.34-.05s.88-.09,1.29-.15l1.2-.2c.38-.08.73-.16,1.06-.26v2.18a19.52,19.52,0,0,1-2.45.5,21.42,21.42,0,0,1-2.82.19,9.47,9.47,0,0,1-3.38-.54,5.77,5.77,0,0,1-2.32-1.54,6.45,6.45,0,0,1-1.34-2.48,11.74,11.74,0,0,1-.43-3.33,9.88,9.88,0,0,1,.46-3.05,7.61,7.61,0,0,1,1.36-2.53,6.43,6.43,0,0,1,5.12-2.36,7.37,7.37,0,0,1,2.83.5,5.75,5.75,0,0,1,2.08,1.42,6,6,0,0,1,1.27,2.24A9.36,9.36,0,0,1,322,201.19Zm-2.76-.38a5.82,5.82,0,0,0-.19-1.86,4,4,0,0,0-.76-1.45,3.35,3.35,0,0,0-1.27-.95,4.22,4.22,0,0,0-1.76-.35,3.63,3.63,0,0,0-1.59.34,3.74,3.74,0,0,0-1.23.94,4.94,4.94,0,0,0-.84,1.46,6.82,6.82,0,0,0-.39,1.87Z"/>
                  </g>
                }
            </svg>
       )
    }
}

export default Logo;