// Polyfills start
require('../src/components/parts/polyfills/intersectionObserver');
import "core-js/es/array/find";
import "core-js/es/array/from";
import "core-js/es/object/assign";
import "core-js/es/promise";
import "core-js/es/string/includes";
import "core-js/es/symbol";
import "whatwg-fetch";


// Custom delayedForEach
Array.prototype.delayedForEach = function(callback, timeout, thisArg){
    var i = 0,
        l = this.length,
        self = this,
        caller = function(){
            callback.call(thisArg || self, self[i], i, self);
            (++i < l) && setTimeout(caller, timeout);
        };
    if (l <= 0) return;
    caller();
  };
//Polyfills end

import React from "react";
import ReactDOM from "react-dom";
import WebFont from 'webfontloader';

import './assets/styles/Global.scss';

import App from './App';


WebFont.load({
  google: {
    families: ['Saira:300,400,600', 'Rajdhani:400,500,700', 'sans-serif']
  }
});


const AppRouter = () => (

    <App />

);

ReactDOM.render(<AppRouter />, document.getElementById("root"));